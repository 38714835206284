//
//
//
//

export default {
  created() {
    this.$nuxt.error({ status: 404, message: 'Page not found' })
  },
}
